<template>
  <div class="readme-article">
    <h1 id="数据分析">四、数据分析</h1>
    <h2 id="业绩统计">1.业绩统计</h2>
    <p>
      查看业绩统计记录。
    </p>
    <p><img src="@/assets/img/finance/5-1.png" /></p>
    <h2 id="财务统计">2.财务统计</h2>
    <p>
      查看财务统计记录。
    </p>
    <p><img src="@/assets/img/finance/5-2.png" /></p>
  </div>
</template>

<script>
export default {
  name: "finance5-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>